.obfuscated,
.obfuscated * {
    background-color: black !important;
    color: black !important;
    user-select: none !important;
}
html, body {
    min-height: 100%;
    font-size: 16px;
    line-height: 1.2em;
}
body {
    color: #666;
    background-color: #eee;
}
.container {
    padding-right: 0;
    padding-left: 0;
}
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    line-height: 100vh;
    text-align: center;
    vertical-align: center;
    color: #fff;
    font-size: 1.5em;
    font-weight: bold;
    text-shadow: 1px 1px 1px #333;
    background-color: rgba(0,0,0,0.75);
}
header {
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.full-screen-height {
    height: 100vh;
}
h1 {
    font-size: 2rem;
}
h2 {
    font-size: 1.5rem;
    border-bottom: solid 1px #ccc;
    padding-bottom: 15px;
}
h3 {
    font-size: 1.25rem;
}
.panel,
.card {
    border-radius: 10px;
    background: #fff;
    border: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
a.card{
    text-decoration: none;
}

.list-group-item {
    font-size: .8rem;
}

.rounded-pill {
    font-weight: normal
}

.header-counts {
    display: flex;
}

.header-counts .list-group-item {
    background: none;
    padding: 0;
    border: none;
    color: #666;
    min-width: 100px;
    max-width: 140px;
    flex-grow: 1;
}
.card .list-group-item {
    padding: 5px 0;
}

.list-group-item .badge {
    font-size: 0.8rem;
}

.header-counts .badge {
    margin-right: 5px;
    height: 40px;
    line-height: 30px;
    float: left;
}

.scrollable-list {
    padding: 0 12px 0 2px;
    list-style: none;
    max-height: 70vh;
    overflow-y: auto;
}

.scrollable-list .card-body {
    margin: -4px;
}

.button-select-active,
.button-select-active:active {
    background-color: #F8F9FA !important;
    color: black !important;
}

.scrollable-list .card {
    border: solid 4px white;
    margin-bottom: 10px;
}

.card.active-patient {
    border: solid #0d6efd 4px;
}

.form-check-label {
    vertical-align: middle;
}

.unselectable {
    user-select: none;
}

.add-party .btn {
    min-width: 70px;
}

.btn-group.wrap {
    flex-wrap: wrap;
    gap: 1px 2px;
    border-radius: 0.25rem;
    overflow: hidden;
}

.btn-group.wrap>.btn {
    border-radius: 0;
    display: inline-block;
}
