.location-map {
    width: 100%;
    height: 100%;
    max-height: 75vh;
}

.location-circle {
    stroke: #00f;
}

.location-circle:hover {
    stroke: rgb(124, 203, 255);
    stroke-width: 2;
}

.location-circle-with-ulcers {
    stroke: #f00;
}

.location-circle-with-ulcers:hover {
    stroke: rgb(255,150,124);
    stroke-width: 2;
}

.selector-link {
    text-decoration: none;
}
